import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import DashboardLayout from "../../components/Layout/DashboardLayout";

const PersonalDetail = () => {
  return (
    <DashboardLayout>
      <h2>Personal Details</h2>
      <Row>
        <Col xs={12} xl={6} xxl={5} className="personal-detail">
          <h5>YOUR INFORMATION</h5>
          <label className="text-sm">Name</label>
          <p>Ami Trinh</p>
          <label className="text-sm">Email</label>
          <p>phuongdung21dp@gmail.com</p>
          <label className="text-sm">Password</label>
          <p>******</p>

          <div className="action-group">
            <Link to="/dashboard/edit-personal-detail">Edit</Link>
            <Link to="">Remove</Link>
          </div>
        </Col>
        <Col xs={12} xl={6} xxl={5} className="personal-detail">
          <h5>YOUR ADDRESSES</h5>
          <p>You don't have a default address.</p>
          <Link
            to="/dashboard/add-new-address"
            className="btn btn-outline-primary btn-w230"
          >
            Add New Address
          </Link>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default PersonalDetail;
